<template>
	<div>
		<div class="row">
			<div class="col-md-12 text-right">
				<router-link :to="`/quotations/new`">
					<a-button class="btn btn-info" icon="plus" lin>Nueva cotización</a-button>
				</router-link>
				<a-button class="btn btn-success ml7" icon="reload" @click="initModule" />
			</div>
		</div>
		<QuotationsList />
	</div>
</template>

<script>
//
import QuotationsList from '@/components/quotations/list'

export default {
	name: 'QuotationsView',
	components: {
		QuotationsList,
	},
	mounted() {
		this.initModule()
	},
	methods: {
		initModule() {
			this.$store.dispatch('quotations/GET')
		},
	},
}
</script>
