<template>
	<a-spin :tip="spinnerLoaderLabel" size="large" :spinning="spinnerLoader">
		<div class="row">
			<div class="col-md-12 pt20">
				<a-input-search v-model="searchText" placeholder="Ingresa link de TRENGO" enter-button @search="onSearch" />
			</div>
		</div>
		<div class="row pt10">
			<div class="col-md-12 text-right">
				<a-table :columns="columns" :dataSource="searchText == '' ? quotationsList : quotationsListFiltered" rowKey="id">
					<div slot="created_at" slot-scope="record">
						{{ record.fullName }}
						<br />
						el {{ record.created_at }}
					</div>
					<div slot="client_name" slot-scope="record">
						<div v-if="record.client_name">
							{{ record.client_name }}
						</div>
						<a-tag v-if="record.trengo" color="purple"><a :href="record.trengo" target="new">Link Trengo</a></a-tag>
					</div>
					<div slot="contact_data" slot-scope="record">
						{{ record.phone | replaceIfLogicalFalse('') }}
						<br />
						{{ record.email | replaceIfLogicalFalse('') }}
					</div>
					<div slot="total" slot-scope="record">
						<div v-if="record.service_tag">
							<b>Servicio {{ record.service_tag }}</b>
						</div>
						{{ record.total }}
					</div>
					<div slot="control_sheet" slot-scope="record">
						<a-tag class="pointer" color="red" @click="downloadQuotation(record.id)">
							<a-icon type="file-pdf" />
						</a-tag>
						<a-tag class="pointer" color="blue" @click="getLinkQuotation(record.id)">
							<a-icon type="link" />
						</a-tag>
					</div>
					<div slot="action" slot-scope="record">
						<a-button type="info" size="small" icon="edit" @click="onEditQuotation(record.id)" />
						<a-button class="btn btn-info ml7" type="info" size="small" icon="copy" @click="copyMessage(record.id)" />
						<a-button class="btn btn-success ml7" type="success" size="small" icon="calendar" @click="scheduleAppointment(record.id)" v-if="!record.scheduled" />
					</div>
				</a-table>
			</div>
		</div>
	</a-spin>
</template>

<script>
import { mapGetters } from 'vuex'
import copy from 'copy-to-clipboard'
import Swal from 'sweetalert2'

export default {
	name: 'QuotationsListComponent',
	computed: {
		...mapGetters('quotations', ['spinnerLoaderLabel', 'spinnerLoader', 'quotationsList', 'downloadLink']),
	},
	data() {
		return {
			searchText: '',
			quotationsListFiltered: [],
			columns: [
				{
					title: 'ID',
					dataIndex: 'id',
					key: 'id',
					align: 'center',
				},
				{
					title: 'Asesor de Servicio',
					scopedSlots: { customRender: 'created_at' },
					align: 'center',
				},
				{
					title: 'Cotización',
					scopedSlots: { customRender: 'control_sheet' },
					align: 'center',
				},
				{
					title: 'Cliente/Trengo',
					scopedSlots: { customRender: 'client_name' },
					align: 'center',
				},
				{
					title: 'Datos de Contacto',
					scopedSlots: { customRender: 'contact_data' },
					align: 'center',
				},
				{
					title: 'Total',
					scopedSlots: { customRender: 'total' },
					align: 'center',
				},
				{
					title: 'Acciones',
					scopedSlots: { customRender: 'action' },
					align: 'right',
				},
			],
			onlyCopy: false,
		}
	},
	mounted() {
		this.$store.dispatch('quotations/GET')
	},
	methods: {
		onEditQuotation(id) {
			this.$store.dispatch('quotations/GET_ONE', { id, redirect: true })
		},
		downloadQuotation(id) {
			this.$store.dispatch('quotations/GET_QUOTATION', { id })
		},
		getLinkQuotation(id) {
			this.$store.dispatch('quotations/GET_QUOTATION', { id, onlyCopy: true })
		},
		copyMessage(id) {
			this.onlyCopy = true
			this.getLinkQuotation(id)
		},
		onSearch(value) {
			if (value != '') {
				this.quotationsListFiltered = []
				value = value.toLowerCase()
				this.quotationsListFiltered = this.quotationsList.filter((obj) =>
					Object.values(obj).some((val) => {
						if (typeof val == 'string' && val.toLowerCase().includes(value)) {
							return true
						}
					}),
				)
			}
		},
		scheduleAppointment(id) {
			Swal.fire({
				title: 'Atención',
				text: '¿Deseas generar una cita a partir de esta cotización?',
				icon: 'warning',
				showCancelButton: true,
				reverseButtons: true,
				confirmButtonColor: '#41b883',
				confirmButtonText: 'Sí, continuar',
				cancelButtonColor: '#f5222e',
				cancelButtonText: 'No, cancelar',
			}).then(async (result) => {
				if (result.isConfirmed) {
					//
					this.$store.dispatch('quotations/GET_ONE', { id, redirect: false }).then(() => {
						this.$store.commit('serviceOrders/SET_STATE', {
							enableWizardModal: true,
							quotationID: id,
						})
					})
				}
			})
		},
	},
	watch: {
		downloadLink(newValue) {
			if (newValue != '') {
				if (this.onlyCopy) {
					copy(`Hermano/a biker, checa tu cotización en este link: \n \n👇🏽👇🏽👇🏽 \n \n${newValue} \n \n¿Gustas que te agende una cita? 😃😃😃`)
					this.onlyCopy = false
				} else {
					copy(newValue)
				}
				this.$store.commit('quotations/SET_STATE', {
					downloadLink: '',
				})
			}
		},
	},
}
</script>
